import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

export default function QueryModal({ open, setOpen, handleSearch, query, setQueries }) {

    useEffect(() => { console.log(query) }, [query]);

    return <Modal centered show={open} onClose={() => { setOpen(false) }}>
        <div className="mc-query-container">
            <input type="text" name="coupon_code" onChange={(e) => { setQueries({ ...query, [e.target.name]: e.target.value }) }} placeholder="Enter you coupon code" />
            <div className="mc-btn-container">
                <button className="mc-btn mc-search-submit-btn" onClick={() => { handleSearch(query); }}>Search</button>
                <button className="mc-btn mc-search-submit-btn" onClick={() => { setOpen(false) }}>Cancel</button>
            </div>
        </div>
    </Modal>
}