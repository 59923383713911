import Login from "./pages/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import Main from "./pages/Main";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CouponData from "./pages/CouponData";
import Content from "./components/Content";
import Coupans from "./pages/Coupans";
import AllUsersData from "./pages/AllUsersData";
import DispatchCoupons from "./pages/DispatchCoupon";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/view/:id" element={<CouponData />} />
        <Route path="/coupons" element={<Coupans/>}/>
        <Route path="/users" element={<AllUsersData/>}/>
        <Route path="/dispatch-coupon" element={<DispatchCoupons/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
