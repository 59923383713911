import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { baseUrl } from '../constant';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../Layout/Layout';
import Loader from '../components/loader';
import convertStatusCode from '../services/convertStatusCode';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { FaFilter } from 'react-icons/fa';
import QueryModal from '../components/QueryModal';

// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }
const DispatchCoupons = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryModal, setQueryModal] = useState(false);
  const [query, setQueries] = useState({});

  // const [feedback, setFeedback] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [pagination, setPagination] = useState(0);
  let [couponId, setCouponId] = useState(0);
  let [refresh, setRefresh] = useState(0);
  let [allExcelData, setAllExcelData] = useState([]);
  const navigate = useNavigate();

  const columns = [
    { field: 'order_id', headerName: 'Order Id', width: 100 },
    // { field: 'project_name', headerName: 'Project Name', width: 100 },
    { field: 'product_name', headerName: 'Product Name', width: 250 },
    { field: 'brand_name', headerName: 'Brand Name', width: 250 },
    { field: 'sku', headerName: 'Sku', width: 150 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'name', headerxName: 'Name', width: 200 },
    { field: 'coupon_code', headerName: 'Coupon Code', width: 200 },
    { field: 'almond_pin', headerName: 'Almond Pin', width: 100 },
    { field: 'price_point', headerName: 'Price Point', width: 100 },
    {
      field: 'created_at',
      headerName: 'Order Date',
      width: 150,
      renderCell: (cellValues) => {
        if (cellValues.value) {
          const date = new Date(cellValues.value);
          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;
          const formattedToday = `${dd}-${mm}-${yyyy}`; //dd + '/' + mm + '/' + yyyy;
          return <div>{formattedToday}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      field: 'expireAt',
      headerName: 'Expire At',
      width: 150,
      renderCell: (cellValues) => {
        if (cellValues.value) {
          const date = new Date(cellValues.value);
          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;
          const formattedToday = `${dd}-${mm}-${yyyy}`; //dd + '/' + mm + '/' + yyyy;
          return <div>{formattedToday}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      field: 'redeem_date',
      headerName: 'Redeem Date',
      width: 100,
      renderCell: (cellValues) => {
        if (cellValues.value) {
          const date = new Date(cellValues.value);
          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;
          const formattedToday = `${dd}-${mm}-${yyyy}`; //dd + '/' + mm + '/' + yyyy;
          return <div>{formattedToday}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      // renderCell: (cellValues) => {
      //   if (cellValues.value === 5)
      //     return <div>5</div>;
      //   } else if(cellValues.value === 4){
      //     return <div>4</div>;
      //   }else if(cellValues.value === 3){
      //     return <div>Activate</div>;
      //   }else if(cellValues.value === 2){
      //     return <div>Redeemed</div>;
      //   } else{
      //     return <div>check status</div>;
      //   }
      // },
    },
    { field: 'project_name', headerName: 'Project Name', width: 200 },

    {
      field: 'view',
      headerName: 'View',
      sortable: false,
      renderCell: (params) => (
        <button
          className="btn btn-warning"
          style={{ padding: '5px 10px' }}
          onClick={() => handleViewClick(params.row.id)}
        >
          View
        </button>
      ),
    },

    {
      field: 'action',
      headerName: 'Action',
      width: '120',
      sortable: false,
      renderCell: (params) => {
        // console.log("params", params)
        return (
          <>
            {params?.row?.status === 'Admin Activate Coupon' ? (
              <button
                className="btn btn-success"
                style={{ padding: '5px 10px' }}
                onClick={() =>
                  handleConfirmClick(params.row.id, params.row.coupon_code)
                }
              >
                Activate
              </button>
            ) : (
              <button className="btn btn-success p-1" disabled>
                Activated
              </button>
            )}
          </>
        );
      },
    },
  ];

  console.log('datat', data);
  const exportToXLSX = (jsonData, fileName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1'); // Removed the extra '-' here

    const headers = Object.keys(jsonData[0]);
    worksheet.addRow(headers);

    jsonData.forEach((item) => {
      const row = [];
      headers.forEach((header) => {
        row.push(item[header]);
      });
      worksheet.addRow(row);
    });

    // workbook.xlsx.writeBuffer().then((buffer) => {
    //   const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement('a');
    //   a.href = url;
    //   a.download = fileName
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // });
    workbook.csv.writeBuffer().then((buffer) => {
      // Create a Blob and trigger download
      const blob = new Blob([buffer], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, fileName);
    });
  };
  // const exportToXLSX = (jsonData, fileName) => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = - workbook.addWorksheet('Sheet 1');

  //   const headers = Object.keys(jsonData[0]);
  //   worksheet.addRow(headers);

  //   jsonData.forEach((item) => {
  //     const row = [];
  //     headers.forEach((header) => {
  //       row.push(item[header]);
  //     });
  //     worksheet.addRow(row);

  //   })

  //   workbook.xlxs.writeBuffer().then((buffer)=>{
  //     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = fileName
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //   })
  // };

  useEffect(() => {
    console.log(page, size);
    fetchPageData(page, size);
    console.log(page, size);
  }, [page, size, refresh]);

  const handleExportClick = () => {
    exportToXLSX(allExcelData, 'coupon_data.csv');
  };
  const fetchPageData = async (page, size) => {
    try {
      const tokenFromLocalStorage = localStorage.getItem('token');
      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };

      const response = await axios.post(
        `${baseUrl}/api/admin/dispatch-coupon/list?page=${page}&limit=${size}`,
        {},
        configHeaders
      );
      console.log(
        '🚀 ~ file: Coupans.js:50 ~ fetchPageData ~ response:',
        page,
        size,
        response?.data?.data?.coupons
      );

      let result = [];
      let excelData = [];

      response?.data?.data?.coupons?.map((dt, i) => {
        result.push({
          ind: page * size + i,
          order_id: dt?.order_id,
          project_name: dt?.project_name,
          product_name: dt?.product_name,
          product_id: dt?.product_id,
          brand_name: dt?.brand_name,
          sku: dt?.sku,
          mobile: dt?.user?.mobile,
          name: dt?.user?.name,
          coupon_code: dt?.coupon_code,
          almond_pin: dt?.almond_pin,
          price_point: dt?.price_point,
          created_at: dt?.created_at,
          expireAt: dt?.expireAt,
          redeem_date: dt?.redeem_date,
          status: convertStatusCode(dt?.status),
          category_name: dt?.category_name,
          product_name: dt?.product_name,
          cost: dt?.cost,
          id: dt?.id,
        });
        excelData.push({
          id: dt?.id,
          order_id: dt?.order_id,
          order_number: dt?.order_number,
          order_quantity: dt?.order_quantity,
          title: dt?.title,
          brand_name: dt?.brand_name,
          sku: dt?.sku,
          mobile: dt?.user?.mobile,
          name: dt?.user?.name,
          coupon_code: dt?.coupon_code,
          almond_pin: dt?.almond_pin,
          redeem_date: dt?.redeem_date,
          category_name: dt?.category_name,
          product_id: dt?.product_id,
          product_name: dt?.product_name,
          // project_name: dt?.project_name,
          almond_pin: dt?.almond_pin,
          cardId: dt?.cardId,
          cardNumber: dt?.cardNumber,
          cardPin: dt?.cardPin,
          category_name: dt?.category_name,
          specification: dt?.specification,
          activationCode: dt?.activationCode,
          activationurl: dt?.activationurl,
          cost: dt?.cost,
          discount: dt?.discount,
          price_point: dt?.price_point,
          image: dt?.image,
          description: dt?.description,
          poNumber: dt?.poNumber,
          sub_category_name: dt?.sub_category_name,
          qwickcilver_expiry: dt?.qwickcilver_expiry,
          qwickcilver_status: dt?.qwickcilver_status,
          qwikcilver_orderId: dt?.qwikcilver_orderId,
          refno: dt?.refno,
          slug: dt?.slug,
          status: dt?.status,
          publishDate: dt?.publishDate,
          created_at: dt?.created_at,
          expireAt: dt?.expireAt,
          user_id: dt?.user_id,
        });
      });
      setAllExcelData(excelData);
      setData(result);
      setLoading(false);
      setPagination(response?.data?.data?.totalCount);
    } catch (error) {
      if (error?.response.status === 401) {
        navigate('/login');
      }
      console.error('Error fetching data:', error);
    }
  };

  const handleSearch = async () => {
    try {
      console.log({ query });
      const tokenFromLocalStorage = localStorage.getItem('token');
      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };

      const response = await axios.post(
        `${baseUrl}/api/admin/dispatch-coupon/list?page=${page}&limit=${size}`,
        { coupon_code: query.coupon_code },
        configHeaders
      );
      setQueryModal(false);

      if (response.data.success) {
        let result = [];
        let excelData = [];

        response?.data?.data?.coupons?.map((dt, i) => {
          result.push({
            ind: page * size + i,
            order_id: dt?.order_id,
            // project_name: dt?.project_name,
            product_name: dt?.product_name,
            product_id: dt?.product_id,
            brand_name: dt?.brand_name,
            sku: dt?.sku,
            mobile: dt?.user?.mobile,
            name: dt?.user?.name,
            coupon_code: dt?.coupon_code,
            almond_pin: dt?.almond_pin,
            price_point: dt?.price_point,
            created_at: dt?.created_at,
            expireAt: dt?.expireAt,
            redeem_date: dt?.redeem_date,
            status: convertStatusCode(dt?.status),
            category_name: dt?.category_name,
            product_name: dt?.product_name,
            cost: dt?.cost,
            id: dt?.id,
          });
          excelData.push({
            id: dt?.id,
            order_id: dt?.order_id,
            order_number: dt?.order_number,
            order_quantity: dt?.order_quantity,
            title: dt?.title,
            brand_name: dt?.brand_name,
            sku: dt?.sku,
            mobile: dt?.user?.mobile,
            name: dt?.user?.name,
            coupon_code: dt?.coupon_code,
            almond_pin: dt?.almond_pin,
            redeem_date: dt?.redeem_date,
            category_name: dt?.category_name,
            product_id: dt?.product_id,
            product_name: dt?.product_name,
            project_name: dt?.project_name,
            almond_pin: dt?.almond_pin,
            cardId: dt?.cardId,
            cardNumber: dt?.cardNumber,
            cardPin: dt?.cardPin,
            project_name: dt.project_name,
            category_name: dt?.category_name,
            specification: dt?.specification,
            activationCode: dt?.activationCode,
            activationurl: dt?.activationurl,
            cost: dt?.cost,
            discount: dt?.discount,
            price_point: dt?.price_point,
            image: dt?.image,
            description: dt?.description,
            poNumber: dt?.poNumber,
            sub_category_name: dt?.sub_category_name,
            qwickcilver_expiry: dt?.qwickcilver_expiry,
            qwickcilver_status: dt?.qwickcilver_status,
            qwikcilver_orderId: dt?.qwikcilver_orderId,
            refno: dt?.refno,
            slug: dt?.slug,
            status: dt?.status,
            publishDate: dt?.publishDate,
            created_at: dt?.created_at,
            expireAt: dt?.expireAt,
            user_id: dt?.user_id,
          });
        });
        setAllExcelData(excelData);
        setData(result);
        setLoading(false);
        setPagination(response?.data?.data?.totalCount);
      }
    } catch (err) {
      console.log('error while fetching coupons', err.message);
      return err.message;
    }
  };

  // const handlePageChange = (newPage) => {
  //   console.log("Page changed to:", newPage);
  //   setPage(newPage);
  // };
  // const handlePageSizeChange = (newPageSize) => {
  //   console.log("Page size changed to:", newPageSize);
  //   setSize(newPageSize);
  // };

  const handleViewClick = (id) => {
    // Implement logic to display full details for the selected id
    // You can use a modal or navigate to a separate page/component
    console.log('viewId', id);
    navigate(`/view/${id}`);
  };
  const handleConfirmClick = (id, coupon_code) => {
    // const tokenFromLocalStorage = localStorage.getItem("token");
    // console.log("Coupn_code", coupon_code)
    // const configHeaders = {
    //   headers: { "Authorization": `Bearer ${tokenFromLocalStorage}` },
    // };
    // console.log(configHeaders);

    // axios.post(`${baseUrl}/api/admin/confirm/coupons`, { id: JSON.stringify(id), coupon_code: coupon_code }, configHeaders).then((response) => {
    //   console.log("response", response?.data?.sucess);
    //   if (response?.data?.sucess === true) {
    //     console.log("Came inside")
    //     Swal.fire({
    //       // position: 'top-end',
    //       icon: 'success',
    //       title: 'Coupon Activated Successfully',
    //       showConfirmButton: false,
    //       timer: 1500
    //     })

    //     setRefresh(refresh + 1)
    //   }
    // }).catch((error) => {
    //   console.error('Error fetching data:', error);
    //   Swal.fire({
    //     // position: 'top-end',
    //     icon: 'success',
    //     title: 'Something Went Wrong',
    //     showConfirmButton: false,
    //     timer: 1500
    //   })
    // });
    Swal.fire({
      title: 'Do you want to activate the Coupon',
      icon: 'info',
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Confirm',
      denyButtonText: `Cancel`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const tokenFromLocalStorage = localStorage.getItem('token');
        console.log('Coupn_code', coupon_code);
        const configHeaders = {
          headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
        };
        console.log(configHeaders);

        axios
          .post(
            `${baseUrl}/api/admin/confirm/coupons`,
            { id: JSON.stringify(id), coupon_code: coupon_code },
            configHeaders
          )
          .then((response) => {
            console.log('response', response?.data?.sucess);
            if (response?.data?.sucess === true) {
              Swal.fire('Activated', '', 'success');
              console.log('Came inside');
              setRefresh(refresh + 1);
            }
          })
          .catch((error) => {
            console.log('Error', error);
          });
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };

  console.log('data', data);
  return (
    <PageLayout>
      {loading ? (
        <Loader />
      ) : data?.length === 0 ? (
        <div className="result-not-found">Data Not Found</div>
      ) : (
        <>
          <div
            className="ms-4"
            style={{ height: 400, width: '95%', marginTop: '10px' }}
          >
            <div className="d-flex justify-content-between ">
              <button
                type="button"
                className="btn btn-warning ms-3 mb-2 mt-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go Back
              </button>

              <button
                type="button"
                // className='btn mc-filter-btn ms-3 mb-2 mt-2'
                className="btn btn-info ms-3 my-2 d-flex justify-content-center align-items-center"
                onClick={() => {
                  setQueryModal(true);
                }}
              >
                <FaFilter />
                <span className=" ml-1">Filter</span>
              </button>
              {/* <button className='btn btn-info ms-3 mb-2' type='button' onClick={handleExportClick} >Downlaod</button> */}
            </div>

            <DataGrid
              sx={{ marginLeft: '15px' }}
              rows={data}
              columns={columns}
              rowsPerPageOptions={[10, 20, 50]}
              pagination
              pageSize={size}
              rowCount={pagination}
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage + 1)}
              onPageSizeChange={(newPageSize) => setSize(newPageSize)}
              // components={{
              //   Toolbar: CustomToolbar,
              // }}
              autoHeight
              {...data}
              // slots={{ toolbar: GridToolbar }}
            />
          </div>
        </>
      )}

      <QueryModal
        open={queryModal}
        setOpen={setQueryModal}
        query={query}
        setQueries={setQueries}
        handleSearch={handleSearch}
      />
    </PageLayout>
  );
};

export default DispatchCoupons;
