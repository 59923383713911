import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { baseUrl } from '../constant';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../Layout/Layout';
import Loader from '../components/loader';
import convertStatusCode, {
  convertStatusCodeforButton,
} from '../services/convertStatusCode';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { FaFilter } from 'react-icons/fa';
import { FcClearFilters } from 'react-icons/fc';
import { Modal } from 'react-bootstrap';

// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }
const Coupans = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // const [feedback, setFeedback] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [pagination, setPagination] = useState(0);
  let [couponId, setCouponId] = useState(0);
  let [refresh, setRefresh] = useState(0);
  let [removeFilter, setRemoveFilter] = useState(false);
  let [allExcelData, setAllExcelData] = useState([]);

  const navigate = useNavigate();

  const columns = [
    { field: 'project_name', headerName: 'Project Name', width: 200 },
    { field: 'order_id', headerName: 'Order Id', width: 100 },
    // { field: 'project_name', headerName: 'Project Name', width: 100 },
    { field: 'product_name', headerName: 'Product Name', width: 250 },
    { field: 'brand_name', headerName: 'Brand Name', width: 250 },
    { field: 'sku', headerName: 'Sku', width: 150 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'name', headerxName: 'Name', width: 200 },
    { field: 'coupon_code', headerName: 'Coupon Code', width: 200 },
    { field: 'almond_pin', headerName: 'Almond Pin', width: 100 },
    { field: 'price_point', headerName: 'Price Point', width: 100 },
    {
      field: 'created_at',
      headerName: 'Order Date',
      width: 150,
      renderCell: (cellValues) => {
        if (cellValues.value) {
          const date = new Date(cellValues.value);
          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;
          const formattedToday = `${dd}-${mm}-${yyyy}`; //dd + '/' + mm + '/' + yyyy;
          return <div>{formattedToday}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      field: 'expireAt',
      headerName: 'Expire At',
      width: 150,
      renderCell: (cellValues) => {
        if (cellValues.value) {
          const date = new Date(cellValues.value);
          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;
          const formattedToday = `${dd}-${mm}-${yyyy}`; //dd + '/' + mm + '/' + yyyy;
          return <div>{formattedToday}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      field: 'redeem_date',
      headerName: 'Redeem Date',
      width: 100,
      renderCell: (cellValues) => {
        if (cellValues.value) {
          const date = new Date(cellValues.value);
          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;
          const formattedToday = `${dd}-${mm}-${yyyy}`; //dd + '/' + mm + '/' + yyyy;
          return <div>{formattedToday}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      // renderCell: (cellValues) => {
      //   if (cellValues.value === 5)
      //     return <div>5</div>;
      //   } else if(cellValues.value === 4){
      //     return <div>4</div>;
      //   }else if(cellValues.value === 3){
      //     return <div>Activate</div>;
      //   }else if(cellValues.value === 2){
      //     return <div>Redeemed</div>;
      //   } else{
      //     return <div>check status</div>;
      //   }
      // },
    },

    {
      field: 'view',
      headerName: 'View',
      sortable: false,
      renderCell: (params) => (
        <button
          className="btn btn-warning"
          style={{ padding: '5px 10px' }}
          onClick={() => handleViewClick(params.row.id)}
        >
          View
        </button>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: '120',
      sortable: false,
      renderCell: (params) => {
        console.log('params', params);
        return (
          <>
            {params?.row?.status === 'Admin Activate Coupon' ? (
              <button
                className="btn btn-success"
                style={{ padding: '5px 10px' }}
                onClick={() =>
                  handleConfirmClick(params.row.id, params.row.coupon_code)
                }
              >
                Activate
              </button>
            ) : (
              <button className="btn btn-success p-1" disabled>
                {convertStatusCodeforButton(params?.row?.status)}
              </button>
            )}
          </>
        );
      },
    },
  ];

  console.log('datat', data);
  const exportToXLSX = (jsonData, fileName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1'); // Removed the extra '-' here

    const headers = Object.keys(jsonData[0]);
    worksheet.addRow(headers);

    jsonData.forEach((item) => {
      const row = [];
      headers.forEach((header) => {
        row.push(item[header]);
      });
      worksheet.addRow(row);
    });

    // workbook.xlsx.writeBuffer().then((buffer) => {
    //   const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement('a');
    //   a.href = url;
    //   a.download = fileName
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // });
    workbook.csv.writeBuffer().then((buffer) => {
      // Create a Blob and trigger download
      const blob = new Blob([buffer], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, fileName);
    });
  };
  // const exportToXLSX = (jsonData, fileName) => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = - workbook.addWorksheet('Sheet 1');

  //   const headers = Object.keys(jsonData[0]);
  //   worksheet.addRow(headers);

  //   jsonData.forEach((item) => {
  //     const row = [];
  //     headers.forEach((header) => {
  //       row.push(item[header]);
  //     });
  //     worksheet.addRow(row);

  //   })

  //   workbook.xlxs.writeBuffer().then((buffer)=>{
  //     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = fileName
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //   })
  // };

  const handleExportClick = () => {
    exportToXLSX(allExcelData, 'coupon_data.csv');
  };

  const fetchPageData = async (
    page,
    size,
    type,
    sku,
    brandName,
    code,
    status
  ) => {
    let body = {};

    if (type) {
      body = { ...body, productType: type };
    }

    if (sku) {
      body = { ...body, sku: sku };
    }

    if (brandName) {
      body = { ...body, brand_name: brandName };
    }

    if (code) {
      body = { ...body, coupon_code: code };
    }

    if (status) {
      body = { ...body, status: status };
    }

    try {
      const tokenFromLocalStorage = localStorage.getItem('token');
      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };

      const response = await axios.post(
        `${baseUrl}/api/admin/coupons/list?page=${page}&limit=${size}`,
        body,
        configHeaders
      );
      // console.log(
      //   '🚀 ~ file: Coupans.js:50 ~ fetchPageData ~ response:',
      //   page,
      //   size,
      //   response?.data?.data?.coupons
      // );

      let result = [];
      let excelData = [];

      response?.data?.data?.coupons?.map((dt, i) => {
        result.push({
          ind: page * size + i,
          order_id: dt?.order_id,
          project_name: dt?.project_name,
          product_name: dt?.product_name,
          product_id: dt?.product_id,
          brand_name: dt?.brand_name,
          sku: dt?.sku,
          mobile: dt?.user?.mobile,
          name: dt?.user?.name,
          coupon_code: dt?.coupon_code,
          almond_pin: dt?.almond_pin,
          price_point: dt?.price_point,
          created_at: dt?.created_at,
          expireAt: dt?.expireAt,
          redeem_date: dt?.redeem_date,
          status: convertStatusCode(dt?.status),
          category_name: dt?.category_name,
          product_name: dt?.product_name,
          cost: dt?.cost,
          id: dt?.id,
        });
        excelData.push({
          id: dt?.id,
          order_id: dt?.order_id,
          order_number: dt?.order_number,
          order_quantity: dt?.order_quantity,
          title: dt?.title,
          brand_name: dt?.brand_name,
          sku: dt?.sku,
          mobile: dt?.user?.mobile,
          name: dt?.user?.name,
          coupon_code: dt?.coupon_code,
          almond_pin: dt?.almond_pin,
          redeem_date: dt?.redeem_date,
          category_name: dt?.category_name,
          product_id: dt?.product_id,
          product_name: dt?.product_name,
          project_name: dt?.project_name,
          almond_pin: dt?.almond_pin,
          cardId: dt?.cardId,
          cardNumber: dt?.cardNumber,
          cardPin: dt?.cardPin,
          category_name: dt?.category_name,
          specification: dt?.specification,
          activationCode: dt?.activationCode,
          activationurl: dt?.activationurl,
          cost: dt?.cost,
          discount: dt?.discount,
          price_point: dt?.price_point,
          image: dt?.image,
          description: dt?.description,
          poNumber: dt?.poNumber,
          sub_category_name: dt?.sub_category_name,
          qwickcilver_expiry: dt?.qwickcilver_expiry,
          qwickcilver_status: dt?.qwickcilver_status,
          qwikcilver_orderId: dt?.qwikcilver_orderId,
          refno: dt?.refno,
          slug: dt?.slug,
          status: dt?.status,
          publishDate: dt?.publishDate,
          created_at: dt?.created_at,
          expireAt: dt?.expireAt,
          user_id: dt?.user_id,
        });
      });
      setAllExcelData(excelData);
      setData(result);
      setLoading(false);
      setPagination(response?.data?.data?.totalCount);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate('/login');
      }
      console.error('Error fetching data:', error);
    }
  };

  const handleViewClick = (id) => {
    // Implement logic to display full details for the selected id
    // You can use a modal or navigate to a separate page/component
    console.log('viewId', id);
    navigate(`/view/${id}`);
  };
  const handleConfirmClick = (id, coupon_code) => {
    // const tokenFromLocalStorage = localStorage.getItem("token");
    // console.log("Coupn_code", coupon_code)
    // const configHeaders = {
    //   headers: { "Authorization": `Bearer ${tokenFromLocalStorage}` },
    // };
    // console.log(configHeaders);

    // axios.post(`${baseUrl}/api/admin/confirm/coupons`, { id: JSON.stringify(id), coupon_code: coupon_code }, configHeaders).then((response) => {
    //   console.log("response", response?.data?.sucess);
    //   if (response?.data?.sucess === true) {
    //     console.log("Came inside")
    //     Swal.fire({
    //       // position: 'top-end',
    //       icon: 'success',
    //       title: 'Coupon Activated Successfully',
    //       showConfirmButton: false,
    //       timer: 1500
    //     })

    //     setRefresh(refresh + 1)
    //   }
    // }).catch((error) => {
    //   console.error('Error fetching data:', error);
    //   Swal.fire({
    //     // position: 'top-end',
    //     icon: 'success',
    //     title: 'Something Went Wrong',
    //     showConfirmButton: false,
    //     timer: 1500
    //   })
    // });
    Swal.fire({
      title: 'Do you want to activate the Coupon',
      icon: 'info',
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Confirm',
      denyButtonText: `Cancel`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const tokenFromLocalStorage = localStorage.getItem('token');
        console.log('Coupn_code', coupon_code);
        const configHeaders = {
          headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
        };
        console.log(configHeaders);

        axios
          .post(
            `${baseUrl}/api/admin/confirm/coupons`,
            { id: JSON.stringify(id), coupon_code: coupon_code },
            configHeaders
          )
          .then((response) => {
            console.log('response', response?.data?.sucess);
            if (response?.data?.sucess === true) {
              Swal.fire('Activated', '', 'success');
              console.log('Came inside');
              setRefresh(refresh + 1);
            }
          })
          .catch((error) => {
            console.log('Error', error);
          });
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };

  function FilterModal(props) {
    const [type, setType] = useState('');
    const [sku, setSKU] = useState('');
    const [brandName, setBrandName] = useState('');
    const [code, setCode] = useState('');
    const [status, setStatus] = useState('');
    const applyFilter = (e) => {
      e.preventDefault();

      fetchPageData(page, size, type, sku, brandName, code, status);

      setModalIsOpen(false);
    };
    return (
      <Modal
        className="a-modal"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="a-header">
          <h4>Filter</h4>

          <Modal.Title id="contained-modal-title-vcenter">
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                background: '#e41e2b',
                borderRadius: '50%',
                width: '25px',
                height: '25px',
              }}
            >
              <img
                onClick={() => {
                  setModalIsOpen(false);
                }}
                src="/images/crossnew1.png"
                className="a-closeButton"
                style={{ width: '15px', cursor: 'pointer' }}
              />
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="a-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="card" style={{ marginBottom: '0' }}>
                <div className="card-block">
                  <form onSubmit={(e) => applyFilter(e)}>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label required">
                        Status
                      </label>
                      <div className="col-sm-9">
                        <select
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                          className="form-control"
                          style={{ height: '35.5px' }}
                        >
                          <option disabled="disabled" value={''} selected>
                            Select
                          </option>
                          <option value="0">Coupon Generated</option>
                          <option value="2">Coupon Redeemed</option>
                          <option value="5">Admin Activate Coupon</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label required">
                        Type
                      </label>
                      <div className="col-sm-9">
                        <select
                          onChange={(e) => {
                            setType(e.target.value);
                          }}
                          className="form-control"
                          style={{ height: '35.5px' }}
                        >
                          <option disabled="disabled" value={''} selected>
                            Select
                          </option>
                          <option value="physical">Physical</option>
                          <option value="digital">Digital</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Coupon Code
                      </label>
                      <div className="col-sm-9">
                        <input
                          onChange={(e) => setCode(e.target.value)}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Brand</label>
                      <div className="col-sm-9">
                        <input
                          onChange={(e) => setBrandName(e.target.value)}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">SKU</label>
                      <div className="col-sm-9">
                        <input
                          onChange={(e) => setSKU(e.target.value)}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                        id="primary-popover-content"
                      >
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  console.log('data', data);

  useEffect(() => {
    fetchPageData(page, size);
  }, [page, size, refresh, removeFilter]);

  return (
    <PageLayout>
      {loading ? (
        <Loader />
      ) : data?.length === 0 ? (
        <div className="result-not-found">Data Not Found</div>
      ) : (
        <>
          <div
            className="ms-4"
            style={{ height: 400, width: '95%', marginTop: '10px' }}
          >
            <div className="d-flex justify-content-between ">
              <button
                type="button"
                className="btn btn-warning ms-3 mb-2 mt-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go Back
              </button>

              <div className="d-flex">
                <button
                  className="btn btn-info ms-3 my-2 d-flex justify-content-center align-items-center"
                  type="button"
                  onClick={() => setModalIsOpen(true)}
                >
                  <FaFilter />
                  <span className=" ml-1">Filter</span>
                </button>
                <button
                  className="btn btn-info ms-3 my-2 d-flex justify-content-center align-items-center"
                  type="button"
                  onClick={() => setRemoveFilter(!removeFilter)}
                >
                  <FcClearFilters size={24} />
                  <span className=" ml-1">Clear Filter</span>
                </button>
              </div>

              {/* <button
                className="btn btn-info ms-3 mb-2"
                type="button"
                onClick={handleExportClick}
              >
                Downlaod
              </button> */}
            </div>

            <DataGrid
              sx={{ marginLeft: '15px' }}
              rows={data}
              columns={columns}
              rowsPerPageOptions={[10, 20, 50]}
              pagination
              pageSize={size}
              rowCount={pagination}
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage + 1)}
              onPageSizeChange={(newPageSize) => setSize(newPageSize)}
              // components={{
              //   Toolbar: CustomToolbar,
              // }}
              autoHeight
              {...data}
              // slots={{ toolbar: GridToolbar }}
            />
          </div>
          <FilterModal
            show={modalIsOpen}
            onHide={() => setModalIsOpen(false)}
          />
        </>
      )}
    </PageLayout>
  );
};

export default Coupans;
